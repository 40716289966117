import { LitElement, html, css } from 'lit-element';
import M from 'materialize-css';

const defaultProps = {
  options: {
    menuWidth: 300, // Default is 240
    edge: 'left', // Choose the horizontal origin
    closeOnClick: true, // Closes side-nav on <a> clicks, useful for Angular/Meteor
    draggable: true // Choose whether you can drag to open on touch screens
  },
};

class InSidebar extends LitElement {
  static get styles() {
    // This feature is unavailable without shadow DOM
    return css`
      in-sidebar {
        display: block;
      }

      in-sidebar.sidenav {
        position: fixed;
        left: 0;
        top: 0;
        z-index: 1033;

        background-color: #FFF;
        height: 100%;
        margin: 0;
        overflow-y: auto;
        padding-bottom: 60px;
        will-change: transform;

        -webkit-transform: translateX(-100%);
                transform: translateX(-100%);

        -webkit-backface-visibility: hidden;
                backface-visibility: hidden;

        -webkit-transform: translateX(-105%);
                transform: translateX(-105%);
      }

      in-sidebar.sidenav-fixed {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        z-index: 1032;

        box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
        height: auto;
        padding: 0;
      }

      in-sidebar.right-aligned {
        left: auto;
        right: 0;

        -webkit-transform: translateX(105%);
                transform: translateX(105%);
        -webkit-transform: translateX(100%);
                transform: translateX(100%);
      }

      in-sidebar .sidenav-container {
        position: relative;
        min-height: 100%;
      }

      @media only screen and (max-width: 767px) {
        in-sidebar.sidenav {
          max-width: 300px;
        }
      }

      @media (min-width: 768px) {
        in-sidebar.sidenav-fixed {
          transform: translateX(0) !important;
        }
      }

      .sidenav-overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1031;

        background-color: rgba(0,0,0,0.5);
        display: none;
        height: 120vh;
        opacity: 0;
        will-change: opacity;
      }

      .drag-target {
        position: fixed;
        top: 0;
        z-index: 1033;
        height: 100%;
        width: 10px;
      }
    `;
  }

  static get properties() {
    return {
      options: { type: Object }, // { type: String | Object },
      isFixed: { type: Boolean },
    };
  }

  constructor() {
    super();
    this.elementChildren = [];
    this.options = defaultProps.options;
  }

  createRenderRoot() {
    // Render template without shadow DOM. Note that shadow DOM features like
    // encapsulated CSS and slots are unavailable.
    return this;
  }

  isJSON(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  connectedCallback() {
    super.connectedCallback();
    this.elementChildren = Array.from(this.children);

    if (typeof this.options === 'object') {
      // @Prop:"options" is already an object
    } else if (this.isJSON(this.options)) {
      // @Prop:"options" is a string and a valid JSON object, THEN parse as JSON
      this.options = JSON.parse(this.options);
    } else {
      // @Prop:"options" is invalid
      this.options = defaultProps.options;
    }

    // let tempOptions = defaultProps.options;
    // _.merge(tempOptions, this.options);
    // this.options = tempOptions;

    M.Sidenav.init(this, this.options);
  }

  firstUpdated() {
    //
  }

  disconnectedCallback() {
    // Do proper cleanup on Sidenav instances
    let sidenavInstance = M.Sidenav.getInstance(this);
    sidenavInstance.destroy();
  }

  render() {
    this.classList.add('sidenav');
    this.classList.add('hydrated');

    if (this.isFixed) {
      this.classList.add('sidenav-fixed');
    }

    return html`
      <style>${this.constructor.getStyles()}</style>
      <div class="sidenav-container">
        ${this.elementChildren}
      </div>
    `;
  }
}

customElements.define('in-sidebar', InSidebar);
